<template>
  <div class="login">
    <div class="title">
      登录
    </div>
    <van-form @submit="onSubmit">
      <van-field
        ref="mobile"
        v-model="tel"
        type="tel"
        name="tel"
        label="+86"
        placeholder="输入手机号码"
        :rules="[{ validator, message: '请输入手机号' }]"
      />
      <van-field
        v-model="code"
        :type="codetype==1?'password':'text'"
        name="code"
        :label="codeText"
        :placeholder="codeText"
        :rules="[{ required: true, message: '请填写' + codeText }]"
      >
        <template #button>
          <div @click="forgetpwd" v-if="codetype==1?true:false">忘记密码</div>
          <van-button
            native-type="button"
            size="mini"
            :disabled="btnDisabled"
            type="danger"
            @click="forgetpwd"
            v-if="codetype==1?false:true"
          >
            <van-count-down v-show="!showTxt" :time="time" @finish="finish" ref="daojishi">
              <template v-slot="timeData">
                <span class="seconds">{{ timeData.seconds }}后重新发送</span>
              </template>
            </van-count-down>
            <span v-show="showTxt">发送验证码</span>
          </van-button>
        </template>
      </van-field>
      
      <div style="margin: 26px 0">
        <van-button round block type="danger" native-type="submit">
          立即登录
        </van-button>
      </div>
      <div class="meun_type">
        <van-cell>
          <template #title>
            <div @click="typeChange">
              {{loginText}}
            </div>
          </template>
          <!-- <template #default>
            <div @click="regist">
              立即注册
            </div>
          </template> -->
        </van-cell>
      </div>
    </van-form>
    <div class="other_login" v-if="showwxlogin" @click="wetchlogin">
      <van-cell :border="false" title="其他方式登录" />
      <!-- <van-button v-if="openid && shareid==69041" @click="wetchlogin">微信登陆</van-button> -->
      <van-grid :border="false" square column-num="6">
        <van-grid-item>
          <template #icon>
            <i class="iconfont iconweixin1"></i>
          </template>
        </van-grid-item>
      </van-grid>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Login',
  data() {
    return {
      tel: "",
      code:"",
      codetype: 1, // 默认使用手机号和密码登陆
      codeText: '密码', // 默认使用手机号和密码登陆
      loginText: '验证码登陆', // 默认使用手机号和密码登陆
      sendText: '忘记密码', // 默认使用手机号和密码登陆
      btnDisabled: true,
      showTxt: false,
      time: -1,
      shareid: 0,
      merchid: 0,
      openid: null, // openid
      showwxlogin: false,
      iswx: false,
    };
  },
  mounted() {
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;

    this.$parent.getmerchname(this.merchid, "登录");
    this.openid = localStorage.getItem("DK_OPENID");
    if (window.isWeixin()) {
      this.iswx = true; 
    }else {
      this.iswx = false; 
    }
    this.init();
  },
  methods: {
    validator(val) {
      return /1\d{10}/.test(val); // 校验函数返回 true 表示校验通过，false 表示不通过
    },
    init() {
      if (this.iswx && this.openid) {
        let _that = null;
        _that = this;
        _that.$axios
          .post(
            _that.$store.state.domain + "web/user/getwxlogin",
            _that.$qs.stringify({
              openid: _that.openid
            })
          )
          .then(res => {
            console.log(res);
            if (res.data.code == 100000) {
              _that.showwxlogin = true;
            }else {
              _that.showwxlogin = false;
            }
          })
          .catch(err => {
            console.log(err);
          })
      }
    },
    // 点击登录按钮
    onSubmit() {
      let _that = null;
      _that = this;
      _that.$toast({
        type: "loading",
        message: "登陆中...",
        overlay: true,
        forbidClick: true,
        duration: 0
      })
      let openid = null;
      openid = localStorage.getItem("DK_OPENID");
      let wxinfo = null;
      wxinfo = localStorage.getItem("DK_WXINFO");
      _that.$axios.post(
        _that.$store.state.domain + "web/user/login",
        _that.$qs.stringify({
          tel: _that.tel,
          code: _that.code,
          codetype: _that.codetype,
          openid: openid,
          wxinfo: wxinfo
        })
      ).then(function(response){
        console.log(response);
        if (response.data && response.data.code == 100000) {
          localStorage.setItem("DK_UID",response.data.data)
          localStorage.setItem("DK_ISLOGIN",true)
          if (response.data && response.data.openid) {
            localStorage.setItem("DK_OPENID",response.data.openid);
          }
          if (response.data.user.id) {
            localStorage.setItem("DK_MEMBERID",response.data.user.id);
            _that.shareid = response.data.user.id
          }
          if (response.data.user.strId) {
            localStorage.setItem("DK_STRID",response.data.user.strId);
          }
          localStorage.setItem("DK_TOKEN",response.data.user.pwd);
          _that.$notify({
            type:'success',
            message: response.data.msg?response.data.msg:'登陆成功'
          })

          setTimeout(() => {
            _that.$toast.clear();
            _that.$router.go(-1);
          }, 2000);
        }else {
          _that.$toast.clear();
          _that.$notify({
            type:'warning',
            message: response.data && response.data.msg?response.data.msg:'登陆失败'
          })
        }
      }).catch(function(error){
        console.log(error);
        _that.$toast.clear();
      })
    },
    // 点解验证码登陆切换为验证码登陆
    typeChange(){
      this.code = ''
      if (this.codetype == 1) {
        this.codeText = '验证码'
        this.loginText = '密码登陆'
        this.sendText = '忘记密码'
        this.codetype = 2
      }else{
        this.codeText = '密码'
        this.loginText = '验证码登陆'
        this.sendText = '发送验证码'
        this.codetype = 1
      }
    },
    // 忘记密码
    forgetpwd(){
      if (this.codetype==1) {
        console.log('忘记密码');
        this.$router.push({ name: "Forgetpwd",query: { tel: this.tel, shareid: this.shareid, merchid: this.merchid } });
      }else{
        console.log('发送验证码');
        if (this.tel && this.validator(this.tel)) {
          this.$axios.post(
            this.$store.state.domain + "web/user/send_sms",
            this.$qs.stringify({
              tel: this.tel,
              type: 'login'
            })
          )
          this.$refs.daojishi.reset();
          this.time = 60 * 1000;
          this.btnDisabled = true;
          this.showTxt = false;
        }else{
          this.$refs.mobile.focus();
        }
      }
    },
    // 注册按钮
    regist(){
      console.log('注册按钮');
      this.$router.push({ name: 'Rigsit', query: { tel: this.tel, shareid: this.shareid, merchid: this.merchid } })
    },
    // 倒计时完成后执行
    finish() {
      this.btnDisabled = false;
      this.showTxt = true;
    },
    // 微信登陆
    wetchlogin() {
      console.log("sss");
      let _that = null;
      _that = this;
      let wxinfo = null;
      wxinfo = localStorage.getItem("DK_WXINFO");
      _that.$axios
        .post(
          _that.$store.state.domain + "web/user/wetch_login",
          _that.$qs.stringify({
            openid: _that.openid,
            wxinfo: wxinfo
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            localStorage.setItem("DK_UID",res.data.data)
            localStorage.setItem("DK_ISLOGIN",true)
            if (res.data.user.id) {
              localStorage.setItem("DK_MEMBERID",res.data.user.id);
              _that.shareid = res.data.user.id;
            }
            if (res.data.user.strId) {
              localStorage.setItem("DK_STRID",res.data.user.strId);
            }
            localStorage.setItem("DK_TOKEN",res.data.user.pwd);
            _that.$notify({
              type:'success',
              message: res.data.msg?res.data.msg:'登陆成功'
            })
            setTimeout(() => {
              _that.$router.go(-1);
            }, 2000);
          }
        })
        .catch(err => {
          console.log(err);
        })
    }
  }
};
</script>
<style lang="less">
.login {
  padding: 0 20px;
  height: 100%;
  text-align: left;
  background: #fff;
  .title {
    margin-top: 40px;
    font-weight: bold;
    font-size: 26px;
    display: inline-block;
    position: relative;
    color: #414244;

    .underline {
      height: 8px;
      width: 100%;
      background-color: #ED2846;
      display: block;
      position: absolute;
      left: 0;
      bottom: 1px;
      z-index: -1;
    }
  }
  .tip {
    font-size: 14px;
    padding: 10px 0;
    color: #999999;
  }
  .van-form {
    margin-top: 30px;
  }
  .van-cell .van-field__label {
    width: 50px;
  }
  .van-cell{
    padding: 10px 0;
  }
  .van-cell .van-cell__value{
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    
    .van-field__button{
      height: 24px;
      line-height: 24px;
      .van-button::before{
        border: 0;
      }
      .van-button--default{
        border: 0;
      }
      .van-button--small{
        height: 24px;
      }
    }
  }
  .van-cell::after{
    left: 0;
  }
  .meun_type{
    .van-cell .van-cell__value,
    .van-cell .van-cell__title{
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      color: #333;
    }
  }
  .seconds {
    color: #ffffff;
    font-size: 12px;
  }
  .other_login {
    overflow: hidden;
    width: 100%;
    text-align: center;
    padding-top: 20px;
    .van-cell {
      .van-cell__title {
        flex: 1;
      }
    }
    .van-grid {
      align-items: center;
      justify-content: center;
      .van-grid-item {
        border-radius: 50%;
        overflow: hidden;
        .van-grid-item__content{
          background-color: #f0f0f0;
          .iconfont {
            font-size: 33px;
            color: #1ED76D;
          }
        }
      }
    }
    
  }
}
</style>
